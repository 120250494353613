<template>
  <div class="container-fluid">
    <div class="row">
      <div class="gpe-nav-cookies centre">
        <div class="gpe-cookies animated fadeInUp">
          <div class="container">
            <p class="gpe-cookie-consent-text">
              {{ $t('cookie.cookieConsentGpeFirst') }}
              <a
                href="/cookies-policy"
                role="button"
                target="_blank"
                aria-label="Click here to read our Cookies Policy (opens in a new tab)"
              >{{ $t('cookie.cookieConsentGpeLink') }}</a>{{ $t('cookie.cookieConsentGpeLast') }}
            </p>
            <a
              class="gpe-cookie-accept cookie-accept"
              href="#"
              role="button"
              @click="gpe_add_tracking"
            >
              <i
                class="ion ion-md-close"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    gpe_add_tracking: function(event) {
      event.preventDefault()
      document.cookie = `tracking=true;expires=${new Date(new Date().getTime()+365*60*60*1000*24).toGMTString()}`
    }
  }
}
</script>
